<template>
    <div class="vertical-middle ev-tab-elements">
        <section>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '১.' : '1.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'ব্যবস্থাপকের নাম (মোবাইল ও ই-মেইল সহ)' : 'Name of Manager (including mobile and e-mail)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '২.' : '2.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'উপ- ব্যবস্থাপকের নামসহ সংখ্যা (মোবাইল ও ই-মেইল সহ)' : 'Deputy Manager Name with Number (including Mobile and E-mail)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৩.' : '3.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সহকারী ব্যবস্থাপকের নাম সহ সংখ্যা (মোবাইল ও ইমেইল সহ)' : 'Assistant Manager Name with Number (including Mobile and Email)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৪.' : '4.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মচারী সংখ্যা (মাঠ)' : 'Number of employees (field)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৫.' : '5.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মচারী সংখ্যা (কারখানা)' : 'Number of Employees (Factory)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৬.' : '6.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'অন্যন্য মাসিক বেতনভুক্ত কর্মচারী (সংখ্যা)' : 'Other Monthly Salaried Employees (No.)' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">
                            <small>{{ currentLocale === 'bn' ? 'পর্যবেক্ষণ এবং মন্তব্যঃ' : 'Observations and Comments:' }} </small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৭.' : '7.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিগত পরিদর্শন/পরিবীক্ষণ প্রতিবেদনের সুপারিশের এলাকে গৃহীত ব্যবস্থা ও মতামতঃ' : 'Actions and opinions taken on recommendations of previous inspections/inspection reports:' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৮.' : '8.' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বাগান উন্নয়নে প্রতিবন্ধকতা এবং  সমাধানের বিষয়ে পরিদর্শনকারী কর্মকর্তার বিস্তারিত মতামতঃ' : 'Detailed opinion of the visiting officer on the obstacles and solutions in garden development:' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-5 pt-5">
            <br>
            <p class="border-top pt-2 mt-4 d-inline">{{ currentLocale === 'bn' ? 'পরিদর্শনকারী কর্মকর্তা/কর্মচারীদের স্বাক্ষর' : 'Signature of Inspecting Officer/Employee' }}</p>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>
.vertical-middle table tr th,
.vertical-middle table tr td {
    vertical-align: middle;
}</style>
