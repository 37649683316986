<template>
    <div class="vertical-middle">
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১. চা উন্নয়ন সংক্রান্ত তথ্যঃ (২০২০-২০২১)' : '1. Development Information on Tea: (2020-2021)' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '(ক) সেকশনের নাম্বার সহ ইনফিলিং করা চারার সংখ্যা' : '(a) Number of plants infilling with section number' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '(খ) পরবর্তী বছর সম্প্রসারণ করার জন্য জমি পরিস্কার' : '(b) Clearing of land for next year expansion' }}<small class="text-muted">{{ currentLocale === 'bn' ? '(একর)' : '(Acre)' }}</small></b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '(গ) পুরাতন চা গাছ উৎপাদন' : '(c) Production of old tea plants' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '(ঘ) জমি পুনর্বাসন' : '(d) Land rehabilitation' }}<small class="text-muted">{{ currentLocale === 'bn' ? '(একর)' : '(Acre)' }}</small></b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '২. নার্সারী' : '2. Nursery' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'জাত' : 'Caste' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'প্রাইমারি বেডে অঙ্কুরিত চারা (ক)' : 'Seedlings germinated in primary bed (a)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২য় পর্যায়ের পলিটিউবে স্থাপিত/ অঙ্কুরিত চারা (খ)' : 'Seedlings placed/germinated in 2nd stage polytubes (b)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'রোপণযোগ্য (গ)' : 'plantable (c)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'মোট (ক+খ+গ)' : 'Total (A+B+C)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংগ্রহের উৎস' : 'source of collection' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'সাধারণ বীজ' : 'common seed' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'বাই ক্লোনার বীজ' : 'Buy Cloner Seeds' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৩. চা ব্যতীত অন্য গাছপালা রোপণ (একর)/সংখ্যাঃ' : '3. Plantation of crops other than tea (Acres)/No' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৪. রাবার আবাদ সম্পর্কিতঃ' : '4. Regarding rubber plantation:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদের অনুমতি নেয়া হয়েছে কিনা' : 'Whether permission has been taken for rubber plantation' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদে জমির পরিমাণ (একর)' : 'Amount of land under rubber plantation (acres)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'রাবার উৎপাদনের পরিমাণ' : 'Volume of rubber production' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'রাবার আবাদ হতে প্রাপ্ত অর্থের পরিমাণ' : 'Amount of money received from rubber plantations' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'উৎপাদিত রাবার কোথায় বিক্রয় করা হয়েছে' : 'Where is the manufactured rubber sold?' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৫. উন্নয়ন কর্মকূচী বাস্তবায়নের জন্য কোন ব্যাংক থেকে কি পরিমাণ ঋণ গ্রহণ করা হয়েছে (ত্রিপক্ষীয় চুক্তিপত্রের কপি)' : '5. How much loan has been taken from any bank for implementation of development projects (copy of tripartite agreement)' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৬. বনজ সম্পদ কর্তন সম্পর্কিতঃ' : '6. Regarding Deduction of Forest Resources:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ইতোপূর্বে সর্বশেষ কখন বনজ সম্পদ কর্তন করা হয়েছে' : 'When was the last time forest resources were cut in the past?' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বাংলাদেশ চা বোর্ডের অনুমতি ছিল কিনা (তারিখ ও স্মারক নং উল্লেখসহ)' : 'Whether permission of Bangladesh Tea Board (with mention of date and memo no.)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ কর্তনের পরিমাণ (সংখ্যা)' : 'Amount of forest resource cut (No.)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ বিক্রয় হতে প্রাপ্ত অর্থের পরিমাণ' : 'Amount of money received from sale of forest resources' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বনজ সম্পদ বিক্রয় হতে প্রাড্ত অর্থ দ্বারা চা বাগানের উন্নয়ন মূলক কাজের বিবরণ' : 'Details of tea garden development work with proceeds from sale of forest assets' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'উক্ত অর্থ দাবারা সরকার/ বাংলাদেশ চা বোর্ড/ব্যাংক এর ঋণ পরিশোধ করা হলে তার পরিমাণ' : 'The said amount is the amount if the debt of Dabara Government/Bangladesh Tea Board/Bank is paid' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. দাপ্তরিক কাজে চা বাগান পরিদর্শন সম্পর্কিত (বিগত ০৩ বছর)' : '7. Visiting tea gardens on official work (last 03 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'পরিদর্শনের কারণ' : 'Reason for inspection' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'পরিদর্শনকালীন প্রদত্ত পরামর্শের বিবরণ' : 'Details of advice given during visit' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'পরামর্শ অনুযায়ী সম্পাদিত কাজের বিবরণ ও ফলাফল' : 'Details of work done and results as per suggestion' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ক) বাংলাদেশ চা বোর্ড, প্রধান কার্যালয়' : '(a) Bangladesh Tea Board, Head Office' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(খ) বাংলাধেশ চা গবেষণা ইনস্টিটিউট (বিটিআরআই)- এর বিজ্ঞানীগণ কর্তৃক উপদেশমূলক পরিদর্শন' : '(b) Advisory visit by scientists of Bangladesh Tea Research Institute (BTRI).' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(গ) প্রকল্প উন্নয়ন ইউনিট (পিডিইউ)' : '(c) Project Development Unit (PDU)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঘ) অন্যান্য সরকারী দপ্তর' : '(d) Other Government Departments' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৮. গবেষণা সম্পর্কিতঃ' : '8. Regarding research:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'চলমান গবেষণার বিষয় ও গবেষণার শিরোনাম' : 'Ongoing research topics and research titles' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'জমির পরিমাণ' : 'Amount of land' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'গবেষণার মেয়াদ' : 'Duration of research' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'গবেষণাটির বর্তমান অবস্থা' : 'Current status of research' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ক) বিটিআরআই কর্তৃক চলমান কোন গবেষণার পরীক্ষণ প্লট রয়েছে কিনা?' : '(a) Whether there is any ongoing research trial plot by BTRI?' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(খ) অন্য কোন প্রতিষ্ঠান কর্তৃক পরিচালিত গবেষণার পরীক্ষণ প্লট রয়েছে কিনা?' : '(b) Whether there is a trial plot of research conducted by any other institution?' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৯. ভর্তুকি মূল্যের সার সংক্রান্তঃ' : '9. Regarding subsidized fertilizer:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বাংলাদেশ চা বোর্ড কর্তৃক অনুমোদিত সারের পরিমাণ' : 'Amount of fertilizer approved by Bangladesh Tea Board' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সার কারখানা/গুদাম হতে সংগৃহীত সারের পরিমাণ' : 'Amount of manure collected from the manure factory/warehouse' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সার কারখানা/গুদাম হতে সংগৃহীত সার বাগানে আনয়নের তারিখ' : 'Date of introduction of manure collected from manure factory/warehouse to garden' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'চা বাগানে ব্যবহৃত সারের পরিমাণ' : 'Amount of fertilizer used in tea gardens' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'চা বাগানের গুদামে রক্ষিত অবশিষ্ট সারের পরিমাণ' : 'Amount of residual manure stored in tea garden warehouse' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="5">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১০. চা বাগানে “পোকামাকড় ও রোগবালাই” সম্পর্কিতঃ' : '10. Regarding “Insects and Diseases” in Tea Gardens:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিগত ০৩ বছরে কি ধরনের “পোকামাকড় ও রোগবালাইয়ের আক্রমণ পরিলক্ষিত হয়েছে' : 'What kind of “insect and disease attack” has been observed in the last 03 years' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '“পোকামাকড় ও রোগবালাই” দমনে ব্যবহুত “ইনসেক্টিসাইড” ও “পেস্টিসাইড” ঔষধের নাম এবং প্রস্তুতকারী প্রতিষ্ঠানের নাম' : 'Name of "Insecticide" and "Pesticide" drug used to control "Insects and Diseases" and name of manufacturer' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'ব্যবহৃত “ইনসেক্টিসাইড” ও “পেস্টিসাইডস” সমূহ বিটিআরআই কর্তৃক অনুমোদিত কিনা' : 'Whether the “Insecticides” and “Pesticides” used are approved by BTRI' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'হেক্টর/ একর প্রতি ব্যবহার “ইনসেক্টিসাইড” ও “পেস্টিসাইড” এর পরিমাণ' : 'Amount of “Insecticide” and “Pesticide” used per hectare/acre' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'ব্যবহৃত “ইনসেক্টিসাইড” ও “পেস্টিসাইড” এর কারণে উৎপাদনে প্রভাব' : 'Impact on production due to “insecticides” and “pesticides” used' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ক) পোকামাকড়' : '(a) Insects' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(খ) রোগবালাই' : '(b) Disease' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১১. চা বাগানে পোকামাকড় ও রোগবালাই সম্পর্কিত চার্ট সংরক্ষণ করা হয় কিনাঃ' : '11. Whether charts are maintained regarding pests and diseases in tea gardens:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১২. চা বাগানের বিভিন্ন সেকশনাল কন্টুর প্রেন, কন্টুর পাথ ও পানি নিস্কাশ নের আউটলেট সম্পর্কিতঃ' : '12. Regarding various sectional contour lines, contour paths and drainage outlets of tea gardens:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৩. চা বাগানের বিভিন্ন সকশনে আগাছা ও ও চা গাছের জন্য ক্ষতিকর অন্যান্য গাছের উপস্থিতি সম্পর্কিত বিবরণ এবং তা দমনে গুহীত ব্যবস্থাসমূহঃ' : '13. Details regarding the presence of weeds and other plants harmful to tea plants in different sections of the tea garden and the measures taken to control them:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৪. চা বাগানের সেকশনওয়ারী “Soli Map" করা আছে কিনা, থাকলে তার বিবরণঃ' : '14. Section wise “Soli Map” of tea plantations, details if yes:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১৪. চা বাগানের সেকশনওয়ারী “Soil Map” করা না থাকলে তা বাস্তবায়নের জন্য গৃহীত ব্যবস্থাসমূহঃ' : '14. Measures to be taken for implementation if section wise “Soil Map” of tea garden is not done:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>
.vertical-middle table tr th,
.vertical-middle table tr td {
    vertical-align: middle;
}
</style>
