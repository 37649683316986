import { render, staticRenderFns } from "./tab1.vue?vue&type=template&id=dc545772&"
import script from "./tab1.vue?vue&type=script&lang=js&"
export * from "./tab1.vue?vue&type=script&lang=js&"
import style0 from "./tab1.vue?vue&type=style&index=0&id=dc545772&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./tab1.vue?vue&type=custom&index=0&blockType=td&v-for=(obj%2C%20index)%20in%20labWelBenifits&%3Akey='seasoned_type-'%20%2B%20index"
if (typeof block0 === 'function') block0(component)

export default component.exports