<template>
    <div class="ev-tab-elements-tab-two">
        <b-tabs pills card align="center">
            <b-tab :title="currentLocale === 'bn' ? 'প্রথম খন্ড' : 'First Part'" active>
                <div class="tab-content">
                    <tab1 />
                </div>
            </b-tab>
            <b-tab :title="currentLocale === 'bn' ? 'দ্বিতিয় খন্ড' : 'Second Part'">
                <div class="tab-content">
                    <tab2 />
                </div>
            </b-tab>
            <b-tab :title="currentLocale === 'bn' ? 'তৃতীয় খন্ড' : 'Third Part'">
                <div class="tab-content">
                    <tab3 />
                </div>
            </b-tab>
            <b-tab :title="currentLocale === 'bn' ? 'চতুর্থ খন্ড' : 'Fourth Part'">
                <div class="tab-content">
                    <tab4 />
                </div>
            </b-tab>
            <b-tab :title="currentLocale === 'bn' ? 'পঞ্চম খন্ড' : 'Fifth Part'">
                <div class="tab-content">
                    <tab5 />
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import tab1 from './sub-tabs-for-tab-2/tab1_old.vue'
import tab2 from './sub-tabs-for-tab-2/tab2.vue'
import tab3 from './sub-tabs-for-tab-2/tab3.vue'
import tab4 from './sub-tabs-for-tab-2/tab4.vue'
import tab5 from './sub-tabs-for-tab-2/tab5.vue'
export default {
    components: {
        tab1,
        tab2,
        tab3,
        tab4,
        tab5
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>
.ev-tab-elements-tab-two .tabs .tab-content{
    padding: 0;
}
.ev-tab-elements-tab-two .tabs .card-header .nav-pills .nav-item{
    margin-right: 0!important;
    width: 20%;
    text-align: center;
}
.ev-tab-elements-tab-two .tabs .card-header .nav-pills .nav-item .nav-link{
    border-radius: 0;
}
.ev-tab-elements-tab-two .tabs .tab-content .card-body{
    padding: 10px 0;
}
</style>
