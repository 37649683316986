<template>
    <div>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১. অপরিণত চা (১.৫ বছর)' : '1. Unripe tea (1.5 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'বৎসর' : 'Year' }}</b-th>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? 'সম্প্রসারণ আবাদ' : 'Extension Plantation' }}</b-th>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? 'খন্ড / পুন:আবাদ' : 'Section / Replantation' }}</b-th>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'মোট এলাকা (একর)' : 'Total Area (Acres)'}}</b-th>
                        <b-th rowspan="2">{{ currentLocale == 'bn' ? 'রোপিত জাত' : 'Planted varieties' }}</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'একর' : 'Acre' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'একর' : 'Acre' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(১) চারা রোপণ বছর (২০২০-২০২১)' : '(1) Planting year (2020-2021)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(২) রক্ষণাবেক্ষণ ১ম বছর (২০১৯-২০২০)' : '(2) Maintenance 1st Year (2019-2020)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(৩) রক্ষণাবেক্ষণ ২য় বছর (২০১৮-২০১৯)' : '(3) Maintenance 2nd Year (2018-2019)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(৪) রক্ষণাবেক্ষণ ৩য় বছর (২০১৭-২০১৮)' : '(4) Maintenance 3rd Year (2017-2018)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(৫) রক্ষণাবেক্ষণ ৪র্থ বছর (২০১৬-২০১৭)' : '(5) Maintenance 4th Year (2016-2017)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '২. নতুন চা আবাদি (৬-১০ বছর)' : '2. New tea planters (6-10 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৬' : '2016' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৫' : '2015' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৪' : '2014' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৩' : '2013' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১২' : '2012' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৩. পূর্ণ বয়স্ক চা (১১-৪০ বৎসর)' : '3. Adult tea (11-40 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৬' : '2016' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৫' : '2015' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৪' : '2014' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৪. বয়স্ক চা (৪১-৬০ বৎসর)' : '4. Elderly tea (41-60 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section No' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৬' : '2016' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৫' : '2015' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৪' : '2014' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৫. অতি বয়স্ক চা (৬০ বছরের উর্ধে)' : '5. Very old tea (above 60 years)' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপণের বৎসর' : 'Year of planting' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section no' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ (একর)' : 'Amount of Land (Acres)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (সবুজ পাতা)' : 'Tea yield, kg/acre (green leaf)' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'চা উৎপাদন, কেজি /একর (তৈরী চা)' : 'Tea production, kg/acre (made tea)' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '২০১৬' : '2016' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৫' : '2015' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '২০১৪' : '2014' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="tab-part-3">
            <h6 class="font-weight-bold mb-2">{{ currentLocale == 'bn' ? '৬. চা বাগানের সেকশন ভিত্তিক তথ্যাদিঃ' : '6. Section wise information on tea gardens' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale == 'bn' ? 'সেকশন নং' : 'Section no' }}</b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'ভূমি প্রকৃতির পরিমাণ ও শতকরা হার %' : 'Amount and percentage of soil nature %' }} <small class="text-muted d-block">{{ currentLocale == 'bn' ? 'টিলা/উচ্চ সমভূমি/ সমভূমি' : 'Hills/high plains/ plains' }}</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'জমির পরিমাণ' : 'Amount of land' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(একর)' : '' }}</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'রোপিত চায়ের জাত' : 'Cultivated tea varieties' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'উৎপাদিত কাঁচা চা পাতার পরিমাণ' : 'Amount of raw tea leaves produced' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'উৎপাদিত তৈরি চায়ের পরিমাণ' : 'Amount of tea produced' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'একর প্রতি উৎপাদন' : 'Production per acre' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(কেজি)' : '(KG)' }}</small></b-th>
                        <b-th>{{ currentLocale == 'bn' ? 'উৎপাদনের শতকরা হার' : 'Percentage of production' }}<small class="text-muted d-block">{{ currentLocale == 'bn' ? '(%)' : '(%)' }}</small></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td colspan="2"><strong>{{ currentLocale == 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale == 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale == 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>
.tab-part-3 table tr th,
.tab-part-3 table tr td {
    vertical-align: middle;
}
</style>
