<template>
    <div class="ev-tab-elements">
        <section class="">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ক) চা কারখানায় ব্যবহৃত যন্ত্রপাতি এবং এগুলোর কর্মক্ষমতাঃ' : '7. (a) Machinery used in tea factories and their performance:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SN' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Device Name' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : '' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩)' : '(3)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪)' : '(4)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫)' : '(5)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-4">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (খ) চা কারখানায় ২০২০-২০২১ অর্থবছরে নতুন সংযোজিত যন্ত্রপাতি এবং এগুলোর কর্মক্ষমতাঃ' : '7. (b) Newly added machinery in tea factories during the financial year 2020-2021 and their performance:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'S.N' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'যন্ত্রপাতির নাম' : 'Name of the device' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Number' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'আকৃতি' : 'Shape' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংযোজনের তারিখ' : 'Date of Addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩)' : '(3)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪)' : '(4)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫)' : '(5)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-4">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (গ) চা অবস্থিত চা পক্রিয়াজাত কারখানাটির বাংলাদেশ চা বোর্ড কর্তৃক পদত্ত নিবন্ধন নম্বারঃ' : '7. (c) Registration number issued by the Bangladesh Tea Board of the tea processing factory where the tea is located:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'নিবন্ধন নাম্বার' : 'Registration No' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-4">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ঘ) পরিদর্শনকালীন পর্যন্ত চা প্রত্রিয়াতাত কারখানাটির নিবন্ধন নবায়ন করা হয়েছে কিনাঃ' : '7. (d) Whether the registration of the tea factory has been renewed up to the time of inspection:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'মন্তব্য লিখুন' : 'Write a comment' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-4">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৭. (ঙ) চা বাগানের চা প্রত্রিয়াজাত কারখানা না থাকলে কাঁচা চা পাতা বিক্রয় সম্পর্কিত বিস্তারিতঃ' : '7. (e) Details regarding sale of raw tea leaves if there is no tea factory in the tea garden:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ লিখুন' : 'Enter details' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style></style>
