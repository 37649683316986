<template>
    <div class="ev-tab-elements">
        <section class="">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১০. শ্রম কল্যাণ সুযোগ সুবিধা (বিগত ০৩ বছর):' : '10. Labor Welfare Benefits (Last 03 Years):' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৮-১৯' : '2018-19' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৯-২০' : '2019-20' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০-২১' : '2020-21' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? '(ক) শ্রমিক বাসস্থান' : '(a) Labor accommodation' }}</b-th>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) পাকা/মিরতিংগা টাইপ' : '(1) Paka/Mirtinga type' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(২) মাটি টিনের ছাউনি' : '(2) Soil Tin Canopy' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) কাঁচা / বেড়া ও টিনের / ছনের ছাউনি' : '(3) Thatched / Fencing and Tin / Canopy' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr class="text-center">
                        <b-td colspan="2"><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? '০০' : '00' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? '০০' : '00' }}</strong></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="">
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৮-১৯' : '2018-19' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৯-২০' : '2019-20' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০-২১' : '2020-21' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? '(ক) পয়ঃনিষ্কাশন ব্যবস্থা' : '(a) Sewage system' }}</b-th>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="2"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) কাঁচা লেট্রিন' : '(1) Raw latrine' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) পাকা লেট্রিন' : '(2) Paved latrines' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
<!-- **************** (গ) স্বাস্থ্য সেবা ***************************************** -->
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? '(গ) স্বাস্থ্য সেবাঃ' : '(c) Health Services:' }}</b-th>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="11"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) হাসপাতাল' : '(1) Hospital' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) ডিসপেনসারি' : '(2) Dispensaries' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) বিছানা সংখ্যা' : '(3) Number of beds' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) চিকিৎসক (এমবিবিএস)' : '(4) Physician (MBBS)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) চিকিৎসক (এলএমএফ)' : '(5) Physician (LMF)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) কম্পাউন্ডার' : '(6) Compounders' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) মিডওয়াইফ/নার্স' : '(7) Midwife/Nurse' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) ড্রেসার' : '(8) Dresser' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৯) দাই' : '(9) Dai' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১০) ক্রেস্ট হাউজ' : '(10) Crest House' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১১) ক্লাস এটেনডেন্ট' : '(11) Class Attendant' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
<!-- **************** (ঘ) বিশুদ্ধ পানি সরবরাহ ***************************************** -->
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? '(ঘ) বিশুদ্ধ পানি সরবরাহ' : '(d) Supply of clean water' }}</b-th>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="4"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) গভীর নলকূপ' : '(1) Deep Tube Wells' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) হস্ত চালিত নলকূপ' : '(2) Hand driven tube wells' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) কুয়া (পাকা)' : '(3) kuya (ripe)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) কুয়া (কাঁচা)' : '(4) Kuya (raw)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
<!-- **************** (ঙ) শিক্ষা গ্রহণের সুযোগ (বাগানের ভিতরে) ***************************************** -->
                    <b-tr>
                        <b-th colspan="2">{{ currentLocale === 'bn' ? '(ঙ) শিক্ষা গ্রহণের সুযোগ (বাগানের ভিতরে)' : '(e) Educational opportunities (within garden)' }}</b-th>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="9"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) প্রাথমিক বিদ্যালয় সংখ্যা (পাকা)' : '(1) Number of Primary Schools (Mature)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) প্রাথমিক বিদ্যালয় সংখ্যা (কাঁচা)' : '(2) Number of Primary Schools (Raw)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) ছাত্র/ছাত্রী সংখ্যা' : '(3) Number of students' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) শিক্ষক/শিক্ষিকা সংখ্যা' : '(4) Number of teachers' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) স্কুলে আঙ্গিনায় অবস্থিত লেট্রিন সংখ্যা' : '(5) Number of latrines located in school premises' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) সরকারী প্রাথমিক বিদ্যালয় সংখ্যা' : '(6) Number of Government Primary Schools' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) নিবন্ধিত/ এমপ্রি ভুক্ত বিদ্যালয় সংখ্যা' : '(7) Number of schools registered/MPRs' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) এনজিও পরিচালিত বিদ্যালয় সংখ্যা' : '(8) Number of schools run by NGOs' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৯) বাগান ব্যবস্থাপনায় পরিচালিত বিদ্যালয় সংখ্যা' : '(9) Number of schools under garden management' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style></style>
