<template>
    <div class="ev-tab-elements">
        <section class="Implementation-development-roadmap">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১১. উন্নয়নের পথনকশা বাস্তবায়ন' : '11. Implementation of development roadmap' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'বিবরণ' : 'Details' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৬-২০ পর্যন্ত বাস্তবায়ন অগ্রগতি (স্বল্প মেয়াদী)' : 'Implementation progress up to 2016-20 (short term)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২১-২৫ পর্যন্ত বাস্তবায়ন লক্ষ্যমাত্রা (মধ্যমেয়াদী)' : 'Implementation Targets for 2021-25 (Medium Term)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৬ সাল হতে পরিদর্শনকালীন নভেম্বর/২০২১ মাস পর্যন্ত বাস্তবায়ন অগ্রগতি' : 'Implementation progress from 2016 till November/2021' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'চলতি বছরের নভেম্বর/২০২১ মাস পর্যন্ত বাস্তবায়ন অগ্রগতি' : 'Implementation progress till November/2021 this year' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১) অপরিণত ও পরিণত এলাকায় শূন্যস্থানে নতুন চারা রোপণ (সংখ্যা)' : '(1) Planting of new seedlings in open spaces in immature and mature areas (number)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) চা পুনরাবাদ/খন্ড পুনরাবাদ (একর)' : '(2) Tea Replantation/Area Replantation (Acres)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) চা এলাকা সম্প্রসারণ (একর)' : '(3) Expansion of tea area (acres)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) চা বাগানের জন্য যানবাহন ক্রয়ের পরিমাণ (ট্রাক্টর, ট্রেইলর, ট্রেইলর সহ পাওয়ার টিলার)' : '(4) Amount of purchase of vehicles for tea plantations (tractors, trailers, power tillers with trailers)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) চা বাগানের জন্য সেচ যন্ত্র ক্রয়ের সংখ্যা (৫০ একর, ১০০ একর, ভূ-গর্ভস্থ)' : '(5) Number of Irrigation Equipment Purchased for Tea Gardens (50 Acres, 100 Acres, Underground)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) কীটনাশকের জন্য স্প্রেয়ার ক্রয়ের পরিমাণ (হস্তচালিত, পাওয়ার স্প্রেয়ার)' : '(6) Amount of Purchase of Sprayers for Pesticides (Manual, Power Sprayers)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) চা কারখানা আধুনিকীকরণের জন্য যন্ত্র ক্রয় (যন্ত্রের নাম ও সংখ্যা)' : '(7) Purchase of machinery for modernization of tea factories (name and number of machinery).' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) চা বাগানসমূহের অবকাঠামো উন্নয়ন (রাস্তা, কালভার্ট, ব্রিজ নির্মাণের সংখ্যা)' : '(8) Development of infrastructure in tea gardens (number of construction of roads, culverts, bridges)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৯) বাঁধ/জলাধার নির্মাণের সংখ্যা' : '(9) Number of dams/reservoirs constructed' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১০) শ্রমিকদের জন্য স্বাস্থ্যসম্মত বাসস্থান নির্মাণের সংখ্যা' : '(9) Number of dams/reservoirs constructed' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১১) শ্রমিকদের জন্য স্বাস্থ্যসম্মত শৌচাগার নির্মাণের সংখ্যা' : '(11) Number of construction of sanitary latrines for workers' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১২) পানীয় জলের সুবিধা (হস্তচালিত নলকূপ, গভীর নলকূপ, পাতকুয়া নির্মাণের সংখ্যা)' : '(12) Drinking water facilities (number of construction of manual tube wells, deep tube wells, water wells)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৩) স্বাস্থ্যসেবা-হাসপাতাল/ক্লিনিক/ডিসপেনসারি নির্মাণের সংখ্যা' : '(13) Healthcare-No of construction of hospitals/clinics/dispensaries' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৪) চা বাগানে শ্রমিকদের জন্য ক্রেস হাউস নির্মাণের সংখ্যা' : '(14) No. of construction of cress houses for laborers in tea plantations' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১৫) চা বাগানে মাদার্স ক্লাব নির্মাণ' : '(15) Construction of Mother"s Club in Tea Gardens' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }} </small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>
.Implementation-development-roadmap table tr th,
.Implementation-development-roadmap table tr td{
    vertical-align: middle;
}
</style>
