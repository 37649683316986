<template>
    <div class="evaluation-report pt-3">
        <div class="ev-tab-title p-2 mb-3 bg-light rounded">
          <i class="fas fa-leaf"></i>
          <h5 class="font-weight-bold ml-2">{{ currentLocale === 'bn' ? 'চা বাগানের পরিবীক্ষণ ও মূল্যায়ন প্রতিবেদন' : 'Inspection and Evaluation Report of Tea Plantations' }}</h5>
        </div>
        <b-card class="ev-main-tab" no-body>
        <b-tabs pills card align="center" v-model="tabIndex">
          <b-tab active>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ক' : 'Part-A' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(সাধারণ তথ্য)' : '(General Info)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab1 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-খ' : 'Part-B' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(পরিসংখ্যান তথ্য)' : '(Statistical Info)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab2 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-গ' : 'Part-C' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(জমিতে চা রোপণের তথ্য)' : '(Tea Planting Info)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab3 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ঘ' : 'Part-D' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(উন্নয়ন কার্যক্রম)' : '(Development Activities)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab4 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-ঙ' : 'Part-E' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(চা উৎপাদন বিষয়ক)' : '(Tea Production)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab5 />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <div class="ev-tab-title">
                <strong>{{ currentLocale === 'bn' ? 'অংশ-চ' : 'Part-F' }}</strong>
                <small>{{ currentLocale === 'bn' ? '(ব্যবস্থাপনা বিষয়ক তথ্য)' : '(Management Information)' }}</small>
              </div>
            </template>
            <div class="tab-content">
              <tab6 />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- tab control -->
      <div>
        <b-button-group class="mt-2">
          <b-button @click="tabIndex--">{{ currentLocale === 'bn' ? 'পূর্ববর্তি ধাপ' : 'Previous Stap' }}</b-button>
          <b-button @click="tabIndex++">{{ currentLocale === 'bn' ? 'পরবর্তি ধাপ' : 'Next Stap' }}</b-button>
        </b-button-group>
      </div>
    </div>
</template>
<script>
import tab1 from './tabs/tab1.vue'
import tab2 from './tabs/tab2.vue'
import tab3 from './tabs/tab3.vue'
import tab4 from './tabs/tab4.vue'
import tab5 from './tabs/tab5.vue'
import tab6 from './tabs/tab6.vue'
export default {
  components: {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6
  },
  data () {
    return {
      tabIndex: 1
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  }
}
</script>
<style>
.evaluation-report .card.ev-main-tab{
  border: none;
  background: none;
}
.evaluation-report .card.ev-main-tab .tabs{
  border: none;
}
.evaluation-report .card.ev-main-tab .tabs .tab-content{
  background: #fff;
}
.evaluation-report .card.ev-main-tab .card-header{
  padding: 0 10px;
  background: #f5f5f5;
  border-bottom: none;
  box-shadow: 0 1px 6px rgba(0,0,0,.18);
}
.evaluation-report .card .card-header .nav-pills .nav-item{
  margin-right: 5px;
}
.evaluation-report .card .card-header .nav-pills .nav-link{
  color: var(--alt);
  border-radius: 0.25rem 0.25rem 0 0;
}
.evaluation-report .card .card-header .nav-pills .nav-link.active{
  position: relative;
  color: #fff;
  background-color: var(--alt);
}
.evaluation-report .card .card-header .nav-pills .nav-link.active::before{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--alt);
}
.evaluation-report .ev-tab-title{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.evaluation-report .ev-tab-title i{
  color: var(--success);
  font-size: 1.5rem;
  margin-bottom: 5px;
}
</style>
