<template>
    <div class="ev-tab-elements">
        <b-table-simple responsive bordered>
      <b-tbody>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '১' : '1' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'চা বাগানের নাম, বিটিবি’র রেজিষ্টেশন নং এবং কোন শ্রেণীতে উজারাভূক্ত' : 'Name of tea garden, registration number of BTB and under which category it is cultivated' }}</b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '২' : '2' }}</b-td>
          <b-td>
            {{ currentLocal === 'bn' ? 'চা বাগানের দাপ্তরিক ঠিকানা' : 'The official address of the tea garden' }} <small class="text-muted">{{ currentLocal === 'bn' ? '(মোবাইল নম্বর, ফ্যাক্স, ইমেইল সহ)' : '(including mobile number, fax, email)' }}</small>
          </b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '৩' : '3' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'বাগান কর্তৃপক্ষের নাম ও ঠিকানা' : 'Name and address of garden authority' }} <small class="text-muted">{{ currentLocal === 'bn' ? '(মোবাইল, ফ্যাক্স, ইমেইল)' : '(Mobile, Fax, Email)' }}</small></b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '৪' : '4' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'বাগান পরিদর্শনকারী কর্মকর্তার নাম, পদবী ও পরিদর্শনের তারিখ' : 'Name, designation and date of inspection of the officer inspecting the garden' }} <small class="text-muted">{{ currentLocal === 'bn' ? '(মোবাইল, ফ্যাক্স, ইমেইল সহ)' : '(including Mobile, Fax, Email)' }}</small></b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '৫' : '5' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'পরিদর্শন সময় উপস্থিত বাগান প্রতিনিধির নাম, পদবী' : 'Name, designation of garden representative present at the time of inspection' }} <small class="text-muted">{{ currentLocal === 'bn' ? '(মোবাইল, ফ্যাক্স, ইমেইল সহ)' : '(including Mobile, Email)' }}</small></b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td rowspan="2">{{ currentLocal === 'bn' ? '৬' : '6' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? '(ক) দলিল সম্পাদনের তারিখ ও ইজারার মেয়াদ কাল' : '(a) Date of execution of deed and duration of lease' }}</b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '(খ) রেজিস্টার্ড ইজারা দলিল নং ও তারিখ' : '(b) Registered lease deed No. and date' }}</b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '৭' : '7' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'চা উৎপাদন, ভূমির ব্যবহার ও সম্প্রসারণ আমাদের উপর ভিত্তি করে বর্তমান শ্রেণী' : 'Current class based on tea production, land use and expansion' }}</b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ currentLocal === 'bn' ? '৮' : '8' }}</b-td>
          <b-td>{{ currentLocal === 'bn' ? 'বাগানের সংক্ষিপ্ত ইতিহাস' : 'A brief history of gardens' }}</b-td>
          <b-td><b-form-input></b-form-input></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
</template>
<script>
  export default {
    data () {
      return {}
    },
    computed: {
      currentLocal () {
        return this.$i18n.locale
      }
    }
  }
</script>
<style>
.ev-tab-elements .form-control{
    margin: 0;
}
</style>

labWelBenifits: [
  {
    fiscal_year_id: 6,
    seasoned_type: '',
    ....
  },
  {
    fiscal_year_id: 7,
    seasoned_type: '',
    ....
  },
]

<td v-for="(obj, index) in labWelBenifits" :key="'seasoned_type-' + index">
  v-model="obj.seasoned_type"
</td>
