<template>
    <div class="vertical-middle ev-tab-elements">
        <section>
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '১. চা উৎপাদন সংক্রান্তঃ' : '1. Regarding tea production:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মসূচী' : 'program' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৭' : '2017' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৮' : '2018' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৯' : '2019' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০' : '2020' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২১' : '2021' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ক) চয়নকৃত মোট সবুজ পাতা' : '(a) Selected total green leaves' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(খ) উৎপাদিত মোট তৈরি চায়ের পরিমাণ' : '(b) Total quantity of prepared tea produced' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(গ) উৎপাদিত চায়ের হার (%)' : '(c) Rate of tea produced (%)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঘ) ওয়্যারহাউসের নাম ও প্রেরিত চায়ের পরিমাণ' : '(d) Name of warehouse and quantity of tea dispatched' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঙ) নিলামে বিক্রয়ের জন্য চুক্তিবদ্ধ ক্রোকার হাইস/ ব্রেকার হাউসসমূহের নাম ও বিক্রিত চায়ের পরিমাণ' : '(e) Names of Crocker Highs/Brecker Houses contracted for auction sale and quantity of tea sold.' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(চ) নিলামে বিক্রিত মোট চায়ের পরিমাণ' : '(f) Total quantity of tea sold at auction' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(চা) নিলামে বিক্রয়ের পর ওয়ারহাউস রক্ষিত অবশিষ্ট চায়ের পরিমাণ' : '(Tea) Quantity of remaining tea retained in warehouse after auction sale' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(জ) “এক্স গার্ডল সেল” এর আওতায় কি পরিমাণ চা বিক্রয়ের অনুমোদন নেয়া হয়েছে' : '(h) What quantity of tea has been approved for sale under “X Girdle Sale”.' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঝ) “এক্স গার্ডেল সেল” এর আওতায় কিক্রিত চা হতে রাজস্ব ও সেস পরিশোধের পরিমাণ' : '(j) Amount of revenue and cess payment on tea manufactured under “Ex Girdle Sale”.' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঞ) ব্যবহারযোগ্য চায়ের পরিমাণ' : '(j) Quantity of usable tea' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ট) এক্স গার্ডেল সেল ও নিলামে বিক্রিত চায়ের গড় মূল্য (কেটি/টাকা)' : '(k) Average Price of Tea sold in Ex Girdle Sale and Auction (kt/Rs.)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ঠ) একর প্রতি গড় উৎপাদন' : '(h) Average production per acre' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(ড) কেজি প্রতি উৎপাদনে ব্যয়ের পরিমাণ' : '(i) Production cost per kg' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <p>{{ currentLocale === 'bn' ? '** মোট চা উৎপাদন' : '** Total tea production' }}  &div; {{ currentLocale === 'bn' ? 'মোট চা এলাকা - (চা উৎপাদন হয় না এইরুপ চা এলাকা + ০-৫ বৎসরের অপরিণত চা এলাকা) = একর প্রতি চা উৎপাদন।' : 'Total tea area - (Tea area not producing tea + 0-5 year immature tea area) = Tea production per acre.' }}</p>
                            <p>{{ currentLocale === 'bn' ? '** পরিদর্শনের তারিখঃ' : '**Date of Inspection:' }} <span class="font-weight-bold px-3 border">{{ currentLocale === 'bn' ? '১০/০৬/২০২৩' : '10/06/2023' }}</span>{{ currentLocale === 'bn' ? 'ইং পর্যন্ত।' : 'Till' }}</p>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td>
                            <small>{{ currentLocale === 'bn' ? 'পর্যবেক্ষণ এবং মন্তব্যঃ' : 'Observations and Comments:' }} </small>
                            <b-form-textarea rows="4" max-rows="5"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style>.vertical-middle table tr th,
.vertical-middle table tr td {
    vertical-align: middle;
}</style>
