<template>
    <div class="ev-tab-elements">
        <section class="">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৮. (ক) যানবাহন, সেচ যন্ত্র, সেচের উৎস, কৃষি যন্ত্রপাতি, বিদ্যুৎ (জেনারেটর এবং ট্রান্সফরমারসহ):' : '8. (a) Vehicles, irrigation equipment, sources of irrigation, agricultural machinery, electricity (including generators and transformers):' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SL' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Quantity' }}<small class="text-muted d-block">{{ currentLocale === 'bn' ? '(টি)' : '(Items)' }}</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'নতুন সংযোজন' : 'New addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '' }}</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'SL' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'সংখ্যা' : 'Quantity' }}<small class="text-muted d-block">{{ currentLocale === 'bn' ? '(টি)' : '(Items)' }}</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'নতুন সংযোজন' : 'New addition' }}<small class="text-muted d-block">&nbsp;</small></b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কর্মক্ষমতা' : 'Performance' }} <small class="text-muted d-block">{{ currentLocale === 'bn' ? '(কেজি/ঘন্টা, দিন)' : '(kg/hour, day)' }}</small></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩)' : '(3)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৩)' : '(3)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪)' : '(4)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৪)' : '(4)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫)' : '(5)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৫)' : '(5)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="mt-4">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '8. (খ) খরা মোকাবিলার জন্য সেচ ব্যবস্থার উন্নয়নে কি কি পরিকল্পনা গ্রহণ করা হয়েছেঃ' : '8. (b) What plans have been adopted for the development of irrigation system to combat drought:' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'মন্তব্য লিখুন' : 'Write a comment' }}</b-th>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? '৯. (ক) জন সম্পদঃ' : '9. (a) Public resources:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead class="text-center">
                    <b-tr>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'ক্র' : 'SL' }}</b-th>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'ক. নিবন্ধিত শ্রমিক' : 'A. registered worker' }}</b-th>
                        <b-th>জন</b-th>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'খ. অনিবন্ধিত শ্রমিক' : 'b. unregistered workers' }}</b-th>
                        <b-th>জন</b-th>
                        <b-th rowspan="2">{{ currentLocale === 'bn' ? 'সর্বমোট (ক+খ)' : 'Total (a+b)' }}</b-th>
                        <b-th colspan="4">{{ currentLocale === 'bn' ? 'বাগানে বসবাসকারী জনসংখ্যার তথ্য (বিগত ০৩ বছর)' : 'Data on population living in Bagan (last 03 years)' }}</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০-২১' : '2020-21' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০-২১' : '2020-21' }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৮-১৯' : '2018-19' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০১৯-২০' : '2019-20' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '২০২০-২১' : '2020-21' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(১)' : '(1)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'পুরুষ' : 'Man' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২)' : '(2)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'মহিলা' : 'Woman' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? 'মোট' : 'Total' }}</strong></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="10">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="2" max-rows="4"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
}
</script>
<style></style>
