<template>
    <div class="ev-tab-elements">
        <section class="porisonkhan-table-one">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? 'সরকার কতৃক ইজারাধীন ভূমিতে সৃজিত চা বাগানের ক্ষেতেঃ' : 'In tea plantations created on government leased land:' }}</h6>
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'ক্রম' : 'Order' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'বিষয়' : 'Subject' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'জাত ও সংগ্রহের স্থান' : 'Caste and Place of Collection' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'acre' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>১</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'মোট জমি পরিমাণ' : 'Total land area' }} <small class="text-muted">{{ currentLocale === 'bn' ? '(ইজারাধীন)' : '(under lease)' }}</small></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '২.(ক)' : '2. (a)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা চাষের অধীনে জমির পরিমাণ' : 'Amount of land under tea cultivation' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '২.(খ)' : '2. (b)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা উৎপন্ন হয় না এরূপ চা এলাকা' : 'Such tea areas do not produce tea' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="5"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'ক. অপরিণত চা গাছ (১-৫ বছর)' : 'A. Immature tea plants (1-5 years)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td rowspan="5"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চ. ক্লোন নার্সারী' : 'f. Clone Nursery' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'খ. নতুন চা গাছ (৬-১০ বৎছর)' : 'b. New tea plants (6-10 years)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{  currentLocale === 'bn' ? 'ছ. বীজ নার্সারী' : 'G. Seed nursery' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'গ. পূর্ণ বয়স্ক চা গাছ (১১-৪০ বৎসর)' : 'c. Mature tea tree (11-40 years)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'জ. ছায়াতরু নার্সারী' : 'h. Chhayataru Nursery' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'ঘ. বয়স্ক চা গাছ (৪১ - ৬০ বছর)' : 'd. Older tea plants (41 - 60 years)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? 'ঙ. অতি বয়স্ক চা গাছ (৬০ বছর উর্ধ্ব)' : 'e. Very old tea tree (above 60 years)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-tbody>
                <b-tfoot>
                    <b-tr>
                        <b-th colspan="2" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (ক)' : 'Sub-II (A)' }}</b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? '৭৬৭০ একর' : '7670 Acres' }}</b-th>
                        <b-th colspan="2" class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-২ (খ)' : 'Sub-II (B)' }}</b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? '৭৬' : '76' }}</b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? '৭৬৭০ একর' : '7670 Acres' }}</b-th>
                    </b-tr>
                </b-tfoot>
            </b-table-simple>
        </section>
        <section class="porisonkhan-table-two">
            <h6 class="font-weight-bold mb-2">{{ currentLocale === 'bn' ? 'আবাদীতে ব্যবহৃত মোট ভূমি (উপমোট ২/ক + ২/খ):' : 'Total land used in cultivation (subtotal 2/A + 2/B):' }}</h6>
            <b-table-simple responsive bordered>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '৩.(ক)' : '3. (A)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা বহির্ভূত এলাকা (একর)' : 'Non-Tea Area (Acres)' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td rowspan="3"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) (চা আবাদী হ্রাস) চা উৎপাদন' : '(1) (Reduction in tea plantations) Production of tea' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) (চা আবাদী হ্রাস) মাটি পুনর্বাসন' : '(2) (Reduction of tea plantations) Soil rehabilitation' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale == 'bn' ? '(৩) চা আবাদি পরিত্যক্ত (যদি থাকে)' : '(3) Tea plantations abandoned (if any).' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
        <section class="porisonkhan-table-three">
            <b-table-simple responsive bordered>
                <b-thead>
                    <b-tr>
                        <b-th>{{ currentLocale === 'bn' ? '৩.(খ)' : '3.(B)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'অন্যন্য কৃষি কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for other agricultural purposes' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? '৩.(গ)' : '3.(C)' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'কৃষি বহির্ভূত কাজে ব্যবহৃত ভূমির পরিমাণ' : 'Amount of land used for non-agricultural purposes' }}</b-th>
                        <b-th>{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td rowspan="8"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) রাবার' : '(1) Rubber' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td rowspan="8"></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(১) পতিত জমি' : '(1) Fallen land' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(২) বাঁশ' : '(2) Bamboo' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(২) জলাশয়/পুকুর' : '(2) Water bodies/ponds' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) রোপিত গাছ' : '(3) Planted trees' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৩) মসজিদ/মন্দির/গীর্জা' : '(3) Mosque/Temple/Church' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) প্রাকৃতিক জঙ্গল' : '(4) Natural forest' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৪) করব/শ্মশান' : '(4) cremation/cremation' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) ছন' : '(5) Chhan' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৫) শ্রমিক বসতি/কর্মচারী গৃহ' : '(5) Labor settlement/employee housing' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) ধান ক্ষেত' : '(6) Paddy field' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৬) কারখানা/বাংলো/অফিস' : '(6) Factory/Bungalow/Office' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) কাঁঠাল গাছ/ লেবু' : '(7) Jackfruit tree/lemon' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৭) স্কুল/হাসপাতাল/ ডিসপেনসারি' : '(7) Schools/Hospitals/Dispensaries' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) অন্যান্য ফলজ ও অর্থকরী ফসল' : '(8) Other fruits and cash crops' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '(৮) রাস্তা, সেকশন রাস্তা সহ' : '(8) Roads, including section roads' }}</b-td>
                        <b-td><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-th></b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (খ)' : 'Subtotal-3 (B)' }}</b-th>
                        <b-th></b-th>
                        <b-th></b-th>
                        <b-th class="text-right">{{ currentLocale === 'bn' ? 'উপমোট-৩ (গ)' : 'Subtotal-3 (C)' }}</b-th>
                        <b-th></b-th>
                    </b-tr>
                    <b-tr>
                        <b-td></b-td>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? 'মোট অনুমোদিত জমি উপ-মোট ২(ক) + ২(খ) + ৩(গ):' : 'Total Allowed Land Sub-Total 2(a) + 2(b) + 3(c):' }}</b-td>
                        <b-td class="text-right">{{ currentLocale === 'bn' ? 'একর' : 'Acre' }}</b-td>
                        <b-td></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{ currentLocale === 'bn' ? '৪ (ক)' : '4.(A)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা হার % ' : 'Percentage of tea plantation %' }}<small>{{ currentLocale === 'bn' ? '(চা বোর্ড কর্তৃক রেজিস্ট্রেশন ভুক্ত জমির তুলনায়)' : '(compared to land registered by Tea Board)' }}</small></b-td>
                        <b-td></b-td>
                        <b-td>{{ currentLocale === 'bn' ? '৪ (খ)' : '4.(B)' }}</b-td>
                        <b-td>{{ currentLocale === 'bn' ? 'চা আবাদের শতকরা জার % ' : 'Percentage Jar % of Tea Plantation' }}<small>{{ currentLocale === 'bn' ? '(চা চাষ উপযোগী জমির তুলনায়)' : '(compared to land suitable for tea cultivation)' }}</small></b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="mt-3" bordered responsive>
                <b-tbody>
                    <b-tr>
                        <b-td colspan="6"><strong>{{ currentLocale === 'bn' ? '৫. চা আবাদি শ্রেণীবিন্যাস (ইপ মোট ২(ক) অনুযায়ী)' : '5. Classification of tea plantations (as per IP MOT 2(a))' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ক) ক্লোন (জাতের নাম উল্লেখসহ)- একর' : '(a) Clones (with mention of variety name)- Acres' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(খ) বাই/পলি ক্লোন (জাতের নাম উল্লেখসহ)- একর' : '(b) Bi/Poly clone (with mention of variety name)- Acres' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(গ) সাধারণ বীজ (জাতের নাম উল্লেখসহ)- একর' : '(c) Common seed (including variety name)- Acres' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-table-simple class="mt-3" bordered responsive>
                <b-tbody>
                    <b-tr>
                        <b-td colspan="5"><strong>{{ currentLocale === 'bn' ? '৬. ক. ইজারা ভূক্ত জমির মধ্যে ভবিষ্যতে চা রোপণ যোগ্য জমির পরিমাণ (সম্ভাব্য)' : '6. A. Amount of future tea plantation area (potential) out of leased land' }}</strong></b-td>
                        <b-td><strong>{{ currentLocale === 'bn' ? 'একর' : 'Acare' }}</strong></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(খ) বাধ্যতামূলকভাবে সম্প্রসারণভূক্ত জমির মধ্যে প্রকৃত আবাদী জমির পরিমাণ' : '(b) Extent of actual cultivated land within the compulsorily expanded land' }}
                            <small class="d-block text-muted">{{ currentLocale === 'bn' ? '(অংশ - খ এর ক্রমিক ২ক+২ খ+৬ক অর্থাৎ ইজারাভূক্ত চা চাষযোগ্য মোট জমির ২.৫%)' : '(Sequential 2A+2B+6A of Part-B i.e. 2.5% of total tea cultivable land under lease)' }}</small>
                        </b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(গ) বাধ্যতামূলক সম্প্রসারণ আবাদ বাস্তবায়নঃ' : '(c) Implementation of Compulsory Extension Plantation:' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ঘ) ২.৫% হারে বাধ্যতামূলক সম্প্রসারণ আবাদ বাস্তবায়ন না করলে তৎবিষয়ে সুপারিশ' : '(d) Recommendation on non-implementation of compulsory expansion plantations at the rate of 2.5%' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="3">{{ currentLocale === 'bn' ? '(ঙ) বাগানের চা আবাদ সম্প্রসারণযোগ্য জমিতে কোন মামলা আছে কিনা, থাকলে বিস্তারিত বিবরণ' : '(e) whether there is any suit on garden tea plantation expandable land, if any details' }}</b-td>
                        <b-td colspan="3"><b-form-input></b-form-input></b-td>
                    </b-tr>
                    <b-tr>
                        <b-td colspan="6">
                            <small>{{ currentLocale === 'bn' ? 'মন্তব্যঃ' : 'Comment:' }}</small>
                            <b-form-textarea rows="3" max-rows="5"></b-form-textarea>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </section>
    </div>
</template>
<script>
 export default {
    data () {
        return {}
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    }
 }
</script>
<style>
.porisonkhan-table-one tr:nth-child(-n+2) td:first-child,
.porisonkhan-table-one thead th:first-child,
.porisonkhan-table-one tr:nth-child(-n+2) td:nth-child(4),
.porisonkhan-table-one thead th:nth-child(4){
    text-align: center;
}
.porisonkhan-table-one td:nth-child(3),
.porisonkhan-table-one thead th:nth-child(3){
    border-right: 1px solid #ccc;
}
.porisonkhan-table-one thade th{
    border-bottom: 1px solid #dee2e6;
    background: #f9f9f9;
}
.porisonkhan-table-two{
    margin-top: 2rem;
}
.ev-tab-elements-tab-two .form-control{
    margin: 0;
}
</style>
